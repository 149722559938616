import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Overview`}</h2>
    <p>{`Tokens almost always appear in a group. A group of tokens may be read-only, or they may be used as a way to input data.`}</p>
    <p>{`A read-only group of tokens organizes information in a way that helps users quickly scan and understand the view they're on. For example, a list of topics associated with a repository.`}</p>
    <p>{`Tokens may be used for data input to quickly add and remove items to a list of related attributes. For example, defining a list of GitHub users who can access a private repo.`}</p>
    <h2>{`Anatomy`}</h2>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 2
    }} mdxType="Box">
  <img width="456" alt="token anatomy diagram" src="https://user-images.githubusercontent.com/2313998/137778682-ee35f8e0-72de-4a24-b1bd-e4ea02e2ac49.png" />
  <Box as="ul" mt="0" mdxType="Box">
    <Box as="li" mt="0" mdxType="Box">
      <strong>Leading visual</strong> (optional): Provides additional context (e.g.: user avatar)
    </Box>
    <Box as="li" mdxType="Box">
      <strong>Label</strong> (required): Concise text label
    </Box>
    <Box as="li" mdxType="Box">
      <strong>Remove button</strong> (optional): Removes the token from an editable group of tokens
    </Box>
  </Box>
    </Box>
    <h2>{`Interactivity`}</h2>
    <p>{`Tokens are almost always interactive. When a token is interactive, it will react to cursor hover and keyboard focus.`}</p>
    <p>{`While tokens may have the same interactivity as a button or a link, a token should not be used as a replacement for a button.`}</p>
    <p>{`If a token is a link or button `}<em parentName="p">{`and`}</em>{` it has a remove button, keyboard focus will only go to the link/button. Keyboard users can remove a token by focusing the link/button and pressing "Backspace" or "Delete". When a screen reader is focused on a removable token, it will read the token's label and tell users to press "Backspace" or "Delete" to remove it.`}</p>
    <p>{`Examples of interactions:`}</p>
    <ul>
      <li parentName="ul">{`An issue label token that links to a list of issues with that label`}</li>
      <li parentName="ul">{`A user token that shows a profile summary dialog on hover`}</li>
    </ul>
    <h2>{`Sizing`}</h2>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="tokens rendered at all sizes" src="https://user-images.githubusercontent.com/2313998/137771748-ee4d796a-7bf0-4384-bb75-a239f358ede6.png" />
  <Box mdxType="Box">
    <Box as="p" mt="0" mdxType="Box">
      Different token sizes can be used to maintain visual harmony with the other content on the screen or to maintain a
      comfortable touch target for interactive tokens.
    </Box>
    <Box as="p" mdxType="Box">
      Keep visual hierarchy in mind. Large tokens should not visually compete with elements that have a higher priority.
    </Box>
    <Box as="p" mdxType="Box">A group of tokens should not have tokens of different sizes.</Box>
  </Box>
    </Box>
    <h2>{`Grouping tokens`}</h2>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="a group of tokens flowing over multiple lines" src="https://user-images.githubusercontent.com/2313998/137771744-ee968841-8b5c-437c-83cc-9f693e35e5f0.png" />
  <Box mdxType="Box">
    <Box as="p" mt="0" mdxType="Box">
      A group of tokens should flow like words in a paragraph, not a vertical list. This keeps the group compact to
      improve scannability.
    </Box>
    <Box as="p" mdxType="Box">Tokens that represent different types of data should not be grouped together.</Box>
    <Box as="p" mdxType="Box">Interactive tokens should not be grouped with non-interactive tokens.</Box>
  </Box>
    </Box>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="a group of tokens restricted to one line with a counter for hidden tokens" src="https://user-images.githubusercontent.com/813754/224695100-072f966a-b797-4224-a2fd-8db8cd526f74.png" />
  <Box mdxType="Box">
    <Box as="p" mt="0" mdxType="Box">
      When space is limited, like in a table, some tokens in a group may be hidden. The amount hidden tokens is added in
      a text label, e.g. `+3`. Make sure to add an accessible label like `3 more tokens` to make this screen reader
      accessible.
    </Box>
    <Box as="p" mdxType="Box">
      It is important to make sure that the detail view of the list item allows users to access all tokens.
    </Box>
  </Box>
    </Box>
    <h3>{`Within a text input field`}</h3>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="tokens rendered at all sizes" src="https://user-images.githubusercontent.com/2313998/137771745-049129e6-5a79-4940-bf1b-633e0c203109.png" />
  <Box mdxType="Box">
    <Box as="p" mt="0" mdxType="Box">
      By default, tokens wrap to the next line and expand the height of the input. This allows users to see many tokens
      at once.
    </Box>
    <Box as="p" mdxType="Box">
      To limit how large the input can grow, a maximum height can be defined. After the input grows past that maximum
      height, it will scroll vertically.
    </Box>
  </Box>
    </Box>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="tokens rendered at all sizes" src="https://user-images.githubusercontent.com/2313998/137771747-6ed54c3b-ddc7-42d2-ad55-db702c8d8093.png" />
  <Box as="p" mt="0" mdxType="Box">
    If the input is being used in a spot where the input height cannot grow, tokens can be forced to a single line that
    scrolls horizontally.
  </Box>
    </Box>
    <Box display="flex" alignItems="flex-start" flexDirection={["column", "column", "column", "column", "row"]} sx={{
      gap: 4
    }} mdxType="Box">
    <img width="456" alt="two tokens and text that reads '+5' rendered in an input" src="https://user-images.githubusercontent.com/2313998/139962783-e0e67ecf-65f0-4828-9b7c-fe51a79c81f5.png" />
    <Box as="p" mt="0" mdxType="Box">If it would be overwhelming to show all of the tokens at once, you can choose to only show a specified number of tokens until the input is focused. This functionality can be used whether the tokens wrap to multiple lines or if they're constrained to a single line.</Box>
    </Box>
    <h2>{`Tokens as a way to enter information`}</h2>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="text input with tokens inside with an autocomplete menu below it" src="https://user-images.githubusercontent.com/2313998/137771742-0d6553f2-1d34-44c2-bfcb-f39c56b5b3d1.png" />
  <Box mdxType="Box">
    <Box as="p" mt="0" mdxType="Box">
      If tokens are being used for data input, it should be obvious that the user is in an edit or creation context.
    </Box>
    <Box as="p" mdxType="Box">
      Tokens can be added or removed from a group in the way that makes the most sense for your use case. We recommend
      using an <a href="/components/autocomplete">autocomplete</a> component to allow users to sort through a
      list to pick options.
    </Box>
    <Box as="p" mdxType="Box">
      The remove button is the recommended way for removing a token from a group, but you can hide it if there is a
      simple alternative that makes more sense for your use case. You can also keep the remove button visible while
      still providing other ways to remove the button from a group.
    </Box>
  </Box>
    </Box>
    <h2>{`Accessibility`}</h2>
    <p>{`To make tokens accessible to keyboard users and those who require accessible contrasts, make sure to fulfill the requirements below.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`4.5:1`}</inlineCode>{` contrast between the label (text and icon) of the token and it's background`}</li>
      <li parentName="ul">{`when in focus a token must have a visible focus outline`}</li>
      <li parentName="ul">{`if a token can be removed, the keyboard focus must be moved to the previous token after deletion. If no token remains, it must focus on the input itself.`}</li>
      <li parentName="ul">{`if a close button is used, it’s name should use the token’s label to make it unique`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Token" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/label/"
        }}>{`Label`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      